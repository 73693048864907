import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDropzone } from 'react-dropzone'



export const UploadModal = ({ setShowModal, showModal, packName }) => {


    const [file, setFile] = useState();

    const upload = async () => {
        setShowModal(false)
        var data = new FormData()
        data.append('file', file)
        data.append('pack_name', packName)
        data.append('sk', localStorage.getItem("sk"))
        await fetch('https://adapi.motionbro.com/uploadJsxbin', {
            method: 'POST',
            body: data
        }).then(
            response => response.json()
        ).then(
            res => {
                if (res.status === "success") {
                    alert(".jsxbin file has been uploaded")
                } else {
                    alert("Failed to upload .jsxbin file")
                }
            }
           
        ).catch(
            error => {
                console.log(error)
                alert("Failed to upload .jsxbin file")
            }
        );
    }

    const onDrop = useCallback(acceptedFiles => {

        if (acceptedFiles.length === 0) {
            alert(`File is not available`)
            return;
        }

        const extensions = acceptedFiles[0].path.split('.').reverse()[0]
        if (extensions !== "jsxbin") {
            alert(`Wrong extension. Allowed only .jsxbin files`)
            return;
        }

        setFile(acceptedFiles[0])

    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={showModal}
            contentLabel="Minimal Modal Example"
            style={customStyles}
        >
            <div className="container" style={{ minHeight: "200px", minWidth: "300px" }}>
                <div className="row">
                    <div className="col-10" style={{ fontSize: "18px", fontWeight: "bold", color: "#666" }}>
                        <div className="">
                            Upload .jsxbin for:
                        </div>
                        <div className="pt-1" style={{ fontStyle: "italic", color: "#222" }}>
                            {packName}
                        </div>
                    </div>
                    <div className="col-2">
                        <button type="button" className="btn btn-danger" onClick={() => setShowModal(false)}>X</button>
                    </div>
                </div>
                <div className="row pt-4">
                    <div {...getRootProps()} >
                        <input {...getInputProps()} />
                        <div className="container text-center" style={{ color: "grey", fontSize: "16px", fontWeight: "bold", minHeight: "120px", maxWidth: "240px", border: "1px solid green", paddingTop: "30px" }}>Drag .jsxbin file here or click to select</div>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="container text-center" style={{ fontSize: "14px", fontStyle: "italic" }}>
                        {file?.name}
                    </div>
                </div>
                <div className="row pt-3">
                    <button type="button" className="btn btn-success" onClick={upload}>Upload</button>
                </div>
            </div>
        </ReactModal>
    )
}

