import React from "react";
import MaterialTable from "@material-table/core";
import Header from "./Header";
import JsxbinIcon from "./JsxbinIcon";
import { UploadModal } from "./UploadModal";


export const Packs = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [authorsLookup, setAuthorsLookup] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [packName, setPackName] = useState();

    const isFreeLookup = { 0: 'No', 1: 'Yes' };


    useEffect(() => {
        fetchAuthors();
        fetchPacks();
    }, [])



    function fetchAuthors() {
        const url = 'https://adapi.motionbro.com/getAuthors';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                var lookup = {};
                for (var i of result.result) {
                    lookup[i] = i;
                }
                setAuthorsLookup(lookup);
                setAuthors(result.result);
            });
    };


    function fetchPacks() {
        const url = 'https://adapi.motionbro.com/getPacks';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                setData(result.result);
                setLoadingState(false);
            });
    };


    const columns = [
        { title: "Pack Name", field: "pack_name", searchable: false, align: "left", editable: localStorage.getItem("role") === "admin" ? "always" : "onAdd" },
        { title: "Pack Version", field: "pack_version", searchable: false, align: "left", editable: "always", },
        {
            title: "Pack Author", field: "pack_author", lookup: authorsLookup, searchable: false, align: "left", editable: "onAdd", editComponent: props => (
                <select id="form-select"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    style={{
                        fontSize: "13px",
                        color: "rgba(0, 0, 0, 0.87)",
                        paddingTop: "6px",
                        paddingBottom: "3px",
                        border: "none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                        minWidth: "100px"
                    }}
                >
                    <option key="default_option_key" value="0">Select author</option>
                    {authors.map((e, key) => {
                        return <option key={key} value={e}>{e}</option>;
                    })}
                </select>
            )
        },
        {
            title: "Gumroad Products IDs", field: "gumroad_products_ids", align: "left", editable: "always", editPlaceholder: "Comma separated Products IDs", render: rowData => {
                return <div className="tra">
                    {rowData.gumroad_products_ids.map((e, key) => {
                        return <span className="badge bg-secondary" style={{ margin: "2px", fontSize: "12px" }} key={key}>{e}</span>;
                    })}
                </div>
            }
        },
        { title: "Envato ID", field: "envato_id", align: "left", editable: "always" },
        { title: "Envato API Token", field: "author_api_bearer", align: "left", editable: "always" },
        { title: "Store Product ID", field: "store_product_id", align: "left", editable: "always" },
        { title: "Free Codes Allowed", field: "is_free_code_allowed", align: "left", editable: "always", searchable: false, lookup: isFreeLookup }
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :

                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Packs"
                    actions={[
                        {
                            icon: () => <JsxbinIcon />,
                            tooltip: "Upload jsxbin",
                            onClick: (event, rowData) => {
                                setPackName(rowData.pack_name);
                                setShowModal(true);
                            },
                        },
                    ]}
                    options={{
                        addRowPosition: "first",
                        search: false,
                        columnsButton: true,
                        filtering: true,
                        rowStyle: rowData => ({
                            // backgroundColor: (rowData.tableData.id % 2 === 0) ? '#F5F5F5' : '#FFF',
                            fontSize: "14px"
                        }),
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                    // localization={{ toolbar: { searchPlaceholder: 'Search code' } }}
                    // cellEditable={{
                    //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    //         return new Promise((resolve, reject) => {
                    //             console.log(rowData);
                    //             console.log('newValue: ' + newValue);
                    //             setTimeout(resolve, 1000);
                    //         });
                    //     },
                    //     isCellEditable: (rowData, columnDef) => {
                    //         return columnDef.field === "is_blocked"
                    //     }
                    // }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                if (newData.pack_name != undefined &&
                                    newData.pack_version != undefined) {
                                    const url = 'https://adapi.motionbro.com/addPack';
                                    let gumroadProductsIdsArray = [];
                                    if (newData.gumroad_products_ids) {
                                        gumroadProductsIdsArray = newData.gumroad_products_ids.split(",");
                                    }
                                    newData.is_free_code_allowed = parseInt(newData.is_free_code_allowed);
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "pack_name": newData.pack_name, "pack_version": newData.pack_version,
                                        "pack_author": newData.pack_author, "gumroad_products_ids": gumroadProductsIdsArray,
                                        "envato_id": newData.envato_id, "author_api_bearer": newData.author_api_bearer, "store_product_id": newData.store_product_id,
                                        "is_free_code_allowed": newData.is_free_code_allowed
                                    };
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                setData([result.pack, ...data]);

                                                resolve();
                                            } else {
                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }

                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                newData.is_free_code_allowed = parseInt(newData.is_free_code_allowed);
                                console.log(oldData.is_free_code_allowed);
                                console.log(newData.is_free_code_allowed);
                                if (newData.pack_version !== oldData.pack_version ||
                                    newData.gumroad_products_ids !== oldData.gumroad_products_ids ||
                                    newData.envato_id !== oldData.envato_id ||
                                    newData.author_api_bearer !== oldData.author_api_bearer ||
                                    newData.store_product_id !== oldData.store_product_id ||
                                    newData.is_free_code_allowed !== oldData.is_free_code_allowed
                                ) {
                                    const url = 'https://adapi.motionbro.com/updatePack';
                                    let gumroadProductsIdsArray = [];
                                    if (typeof (newData.gumroad_products_ids) === "string") {
                                        gumroadProductsIdsArray = newData.gumroad_products_ids.split(",");
                                    } else {
                                        gumroadProductsIdsArray = newData.gumroad_products_ids;
                                    }
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "pack_name": newData.pack_name, "pack_version": newData.pack_version,
                                        "gumroad_products_ids": gumroadProductsIdsArray, "envato_id": newData.envato_id,
                                        "author_api_bearer": newData.author_api_bearer, "store_product_id": newData.store_product_id,
                                        "is_free_code_allowed": newData.is_free_code_allowed
                                    }
                                    console.log(_data);
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.index;
                                                newData.gumroad_products_ids = gumroadProductsIdsArray;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);

                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }
                            }),
                        onRowDelete: localStorage.getItem("role") === "admin" ? oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const url = 'https://adapi.motionbro.com/deletePack';
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "pack_name": oldData.pack_name
                                    }
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataDelete = [...data];
                                                const index = oldData.tableData.id;
                                                dataDelete.splice(index, 1);
                                                setData([...dataDelete]);
                                                resolve();
                                            }
                                        });
                                }, 1000)
                            }) : null,
                    }}
                />
        }
        <UploadModal setShowModal={setShowModal} showModal={showModal} packName={packName} />
    </div>;
} 