import React, { useState, useEffect } from "react";


function Header() {
  const currentUrl = window.location.pathname;

  const [role, setRole] = useState();

  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, [])

  const logout = async e => {
    localStorage.removeItem("sk");
    localStorage.removeItem("role");
    window.location.href = "/login";
  };

  return (

    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid navContainer">
        <a className="navbar-brand" href="/"><img className="header-logo img-fluid" src='/assets/logos/logo_black.png' alt=''></img></a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a id='codesLink' className={currentUrl == '/codes' ? "nav-link active" : "nav-link"} href="codes" aria-current={currentUrl == '/codes' ? "page" : ""}>Codes</a>
            </li>
            <li className="nav-item">
              <a id='packsLink' className={currentUrl == '/packs' ? "nav-link active" : "nav-link"} href="packs" aria-current={currentUrl == '/packs' ? "page" : ""}>Packs</a>
            </li>
            {
              role === "admin"
                ?
                <li className="nav-item">
                  <a id='usersLink' className={currentUrl == '/users' ? "nav-link active" : "nav-link"} href="users" aria-current={currentUrl == '/users' ? "page" : ""}>Users</a>
                </li>
                :
                <div></div>
            }

            {/* <li className="nav-item">
                <a id='collectionLink' className="nav-link" href="/collection">Collection</a>
              </li> */}
            {/* <li className="nav-item">
                <a id='faqLink' className={currentUrl == '/faq' ? "nav-link active" : "nav-link"} href="faq" aria-current={currentUrl == '/faq' ? "page" : ""}>FAQ</a>
              </li> */}
          </ul>
          <button className="nav-item" style={{ border: "none", backgroundColor: "transparent", padding: "0px" }} onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
