import React from "react";
import MaterialTable from "@material-table/core";
import Header from "./Header";


export const Users = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);

    const rolesLookup = { "admin": "admin", "author": "author" };

    useEffect(() => {
        fetchUsers();
    }, [])

    function validateEmail(email) {
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    function fetchUsers() {
        const url = 'https://adapi.motionbro.com/getUsers';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                setData(result.result);
                setLoadingState(false);
            });
    };

    const columns = [
        { title: "Username", field: "username", searchable: false, align: "left", editable: "always" },
        { title: "Password", field: "password", searchable: false, align: "left", editable: "always" },
        {
            title: "Role", field: "role", searchable: false, align: "left", editable: "always", lookup: rolesLookup, editComponent: props => (
                <select id="form-select"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    style={{
                        fontSize: "13px",
                        color: "rgba(0, 0, 0, 0.87)",
                        paddingTop: "6px",
                        paddingBottom: "3px",
                        border: "none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                        minWidth: "100px"
                    }}
                >
                    <option key="default_option_key" value="0">Select role</option>
                    <option key="admin_option_key" value="admin">admin</option>
                    <option key="author_option_key" value="author">author</option>
                </select>
            )
        },
        // { title: "Secret", field: "secret", searchable: false, align: "left", editable: "never" },
        { title: "Email", field: "email", searchable: false, align: "left", editable: "always" }
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :

                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Users"
                    options={{
                        addRowPosition: "first",
                        search: false,
                        columnsButton: true,
                        filtering: true,
                        rowStyle: rowData => ({
                            // backgroundColor: (rowData.tableData.id % 2 === 0) ? '#F5F5F5' : '#FFF',
                            fontSize: "14px"
                        }),
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                    // localization={{ toolbar: { searchPlaceholder: 'Search code' } }}
                    // cellEditable={{
                    //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    //         return new Promise((resolve, reject) => {
                    //             console.log(rowData);
                    //             console.log('newValue: ' + newValue);
                    //             setTimeout(resolve, 1000);
                    //         });
                    //     },
                    //     isCellEditable: (rowData, columnDef) => {
                    //         return columnDef.field === "is_blocked"
                    //     }
                    // }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                if (newData.username != undefined &&
                                    newData.password != undefined &&
                                    newData.role != undefined &&
                                    newData.email != undefined &&
                                    newData.email != "" &&
                                    validateEmail(newData.email) === true &&
                                    newData.email != "" &&
                                    newData.password != ""
                                ) {
                                    const url = 'https://adapi.motionbro.com/addUser';
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "username": newData.username, "password": newData.password,
                                        "role": newData.role, "email": newData.email
                                    };
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            console.log(result);
                                            if (result.result === "success") {
                                                setData([result.user, ...data]);

                                                resolve();
                                            } else {
                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }

                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                if (newData.username !== oldData.username ||
                                    newData.password !== oldData.password ||
                                    newData.role !== oldData.role ||
                                    newData.email !== oldData.email
                                ) {
                                    const url = 'https://adapi.motionbro.com/updateUser';
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "username": newData.username, "password": newData.password,
                                        "role": newData.role, "email": newData.email
                                    };
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.index;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);

                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }
                            }),
                        //     onRowDelete: oldData =>
                        //       new Promise((resolve, reject) => {
                        //         setTimeout(() => {
                        //           const dataDelete = [...data];
                        //           const index = oldData.tableData.id;
                        //           dataDelete.splice(index, 1);
                        //           setData([...dataDelete]);

                        //           resolve();
                        //         }, 1000)
                        //       }),
                    }}
                />
        }
    </div>;
} 