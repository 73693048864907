import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const PurchaseCodes = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [authorsLookup, setAuthorsLookup] = useState({});
    const [packNames, setPackNames] = useState([]);

    const originLookup = { "envato": 'Envato', "gumroad": 'Gumroad', "store": "Store", "custom": "Custom" };
    const isBlockedLookup = { 0: 'No', 1: 'Yes' };
    const isFreeLookup = { 0: 'No', 1: 'Yes' };


    useEffect(() => {
        fetchAuthors();
        fetchPacks();
        fetchData();
    }, [])

    function fetchData() {
        const url = 'https://adapi.motionbro.com/getCodes';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                for (var _item of result.result) {
                    _item.added_timestamp = moment(_item.added_timestamp * 1000).format();
                    if (_item.last_used_timestamp != null) {
                        _item.last_used_timestamp = moment(_item.last_used_timestamp * 1000).format();
                    }
                }
                setData(result.result);
                setLoadingState(false);
            });
    };


    function fetchAuthors() {
        const url = 'https://adapi.motionbro.com/getAuthors';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                var lookup = {};
                for (var i of result.result) {
                    lookup[i] = i;
                }
                setAuthorsLookup(lookup);
                setAuthors(result.result);
            });
    };


    function fetchPacks() {
        const url = 'https://adapi.motionbro.com/getPacks';
        const data = { "sk": localStorage.getItem("sk") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                var res = []
                for (var i of result.result) {
                    if (!res.includes(i.pack_name)) {
                        res.push(i.pack_name);
                    }
                }
                setPackNames(res);
            });
    };



    const columns = [
        { title: "Purchase Code", field: "purchase_code", align: "left", editable: "onAdd" },
        {
            title: "Packs", field: "packs_names", searchable: false, align: "left", editable: "onAdd", editPlaceholder: "Packs names (comma separated)", render: rowData => {
                return <div className="tra">
                    {rowData.packs_names.map((e, key) => {
                        return <span className="badge bg-secondary" style={{ margin: "2px", fontSize: "12px" }} key={key}>{e}</span>;
                    })}
                </div>
            }
        },
        {
            title: "Pack Author", field: "pack_author", lookup: authorsLookup, searchable: false, align: "left", editable: "onAdd", editComponent: props => (
                <select id="form-select"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    style={{
                        fontSize: "13px",
                        color: "rgba(0, 0, 0, 0.87)",
                        paddingTop: "6px",
                        paddingBottom: "3px",
                        border: "none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                        minWidth: "100px"
                    }}
                >
                    <option key="default_option_key" value="0">Select author</option>
                    {authors.map((e, key) => {
                        return <option key={key} value={e}>{e}</option>;
                    })}
                </select>
            )
        },
        { title: "Origin", field: "origin", searchable: false, lookup: originLookup, align: "left", editable: "never" },
        { title: "Blocked", field: "is_blocked", searchable: false, lookup: isBlockedLookup, align: "left", editable: "onUpdate" },
        { title: "Uses Count", field: "uses_count", type: "numeric", searchable: false, filtering: false, align: "left", editable: "never" },
        { title: "Buyer", field: "buyer", searchable: false, align: "left", hidden: true, editable: "never" },
        { title: "Added date", field: "added_timestamp", searchable: false, filtering: false, align: "left", editable: "never", type: "datetime" },
        { title: "Last used", field: "last_used_timestamp", searchable: false, filtering: false, align: "left", hidden: true, editable: "never" },
        { title: "Created by", field: "created_by", searchable: false, filtering: false, align: "left", hidden: true, editable: "never" },
        { title: "Modified by", field: "last_modified_by", searchable: false, filtering: false, align: "left", hidden: true, editable: "never" },
        { title: "Is Free", field: "is_free", searchable: false, lookup: isFreeLookup, align: "left", editable: "onUpdate" }
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Purchase Codes"
                    options={{
                        addRowPosition: "first",
                        search: false,
                        columnsButton: true,
                        filtering: true,
                        rowStyle: rowData => ({
                            // backgroundColor: (rowData.tableData.id % 2 === 0) ? '#F5F5F5' : '#FFF',
                            fontSize: "14px"
                        }),
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                    // localization={{ toolbar: { searchPlaceholder: 'Search code' } }}
                    // cellEditable={{
                    //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    //         return new Promise((resolve, reject) => {
                    //             console.log(rowData);
                    //             console.log('newValue: ' + newValue);
                    //             setTimeout(resolve, 1000);
                    //         });
                    //     },
                    //     isCellEditable: (rowData, columnDef) => {
                    //         return columnDef.field === "is_blocked"
                    //     }
                    // }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                if (newData.purchase_code != undefined &&
                                    newData.purchase_code.length > 3 &&
                                    newData.packs_names != undefined &&
                                    newData.pack_author != undefined) {
                                    const packsArray = newData.packs_names.split(",");
                                    for (const _packName of packsArray) {
                                        if (!packNames.includes(_packName.trim())) {
                                            alert(`Pack ${_packName.trim()} does not exist`);
                                            reject();
                                            return;
                                        }
                                    }
                                    const url = 'https://adapi.motionbro.com/addCode';
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "purchase_code": newData.purchase_code,
                                        "packs_names": packsArray, "pack_author": newData.pack_author, "is_free": newData.is_free
                                    };
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .catch(e => {
                                            console.log(e)
                                            alert("Something is wrong. Incorrect pack author??");
                                            reject();
                                            return;
                                        })
                                        .then(response => {
                                            console.log(response);
                                            return response.json();
                                        })
                                        .catch(e => {
                                            console.log(e)
                                            alert("Something is wrong. Incorrect pack author??");
                                            reject();
                                            return;
                                        })
                                        .then(result => {
                                            if (result.result === "success") {
                                                var _item = result.code;
                                                _item.added_timestamp = moment(_item.added_timestamp * 1000).format();
                                                if (_item.last_used_timestamp != null) {
                                                    _item.last_used_timestamp = moment(_item.last_used_timestamp * 1000).format();
                                                }
                                                setData([_item, ...data]);

                                                resolve();
                                            } else {
                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }

                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                newData.is_blocked = parseInt(newData.is_blocked);
                                if (newData.is_free !== undefined) {
                                    newData.is_free = parseInt(newData.is_free);
                                }
                                if (newData.is_blocked !== oldData.is_blocked ||
                                    newData.is_free !== oldData.is_free
                                ) {
                                    const url = 'https://adapi.motionbro.com/updateCode';
                                    const _data = { "sk": localStorage.getItem("sk"), "purchase_code": oldData.purchase_code, "is_blocked": newData.is_blocked, "is_free": newData.is_free }
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.index;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);

                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }
                            }),
                        onRowDelete: localStorage.getItem("role") === "admin" ? oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const url = 'https://adapi.motionbro.com/deleteCode';
                                    const _data = {
                                        "sk": localStorage.getItem("sk"), "purchase_code": oldData.purchase_code
                                    }
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataDelete = [...data];
                                                const index = oldData.tableData.id;
                                                dataDelete.splice(index, 1);
                                                setData([...dataDelete]);

                                                resolve();
                                            }
                                        });
                                }, 1000)
                            }) : null,
                    }}
                />
        }
    </div>;
} 