// import React, { Component } from 'react';
// import LoginPage from "./components/LoginPage"
// import TestPage from "./components/TestPage";
// import MainPage from "./components/MainPage";

import { Navigate, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Packs } from "./components/Packs";
import { PurchaseCodes } from "./components/PurchaseCodes";
import LoginPage from "./components/LoginPage";
import { Users } from "./components/Users";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/codes" element={<PurchaseCodes />} />
        {/* {
          !localStorage.getItem('sk') ? <Navigate from='/' to='/login' /> : ''
        } */}
        <Route path="/" element={localStorage.getItem('sk') ? <Navigate replace to="/codes" /> : <Navigate replace to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/packs" element={<Packs />} />
        <Route path="/users" element={<Users />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        {/* <Route path="/test" element={<TestPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;